import React from 'react';

import {PageHeader} from 'src/layouts/PageHeader';
import {Button} from 'src/components/atoms/Button';
import {FluidImg} from 'src/embeds/image/image-dato';
import * as sys from '../../system';
import * as css from './header-home.css';
import {sectionCover} from "./header-home.css";

export default function HomeHeaderCover({heading, preHeading, contentNode, image, ctas, blocks, customData, ...rest}) {
    customData = JSON.parse(customData);

    return (
        <PageHeader
            {...{
                alternative: false,
                dark: false,
            }}
        >
            <section css={[css.sectionCover]}>
                <div css={[sys.frame, sys.wrapLast, css.layoutGrid]}>
                    <div css={[css.textStack]}>
                        <p className="f-mono" css={[css.preHeading]}>{preHeading}</p>
                        <h1 css={[css.h1]}>{heading}</h1>
                        <div
                            css={[css.subheading]}
                            dangerouslySetInnerHTML={{
                                __html: contentNode.childMarkdownRemark.html,
                            }}
                        />
                        <ul role="list" css={[css.buttonFlow]}>
                            {ctas.map((cta, i) => (
                                <li role="listitem" key={cta.text}>
                                    <Button
                                        css={[css.btn]}
                                        to={cta.url}
                                        aria-label={cta.a11yText || cta.text}
                                        secondary={i > 0}
                                    >
                                        {cta.text}
                                    </Button>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                {
                    !!image && (
                        <div style={{position: 'absolute', height: '100%', width: '100%', zIndex: '-1', top: 0}}>
                            <FluidImg data={image}
                                      pictureStyle={{objectFit: 'cover'}} style={{height: '100%'}} maxWidth={null} priority fadeIn={0}/>
                        </div>
                    )
                }
            </section>
        </PageHeader>
    );
}
